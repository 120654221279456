import React from 'react'
import BestSong from '../../components/BestSong/BestSong'
import Album from '../../components/Slider/Slider'

const Mp3Chart = () =>{
    return (
        <>
            <BestSong/>
            <Album/>
        </>
    )
}
export default Mp3Chart;