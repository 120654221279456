import React from 'react'
import AlbumForm from '../../componentAdmin/Albums/AlbumForm'
const CreateNewAlbum =(props) =>{
    
    return (
        <>
            <AlbumForm id={null} title="Tạo mới Album"/>
        </>
    );
}
export default CreateNewAlbum;